import {
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE
} from '../constants/CategoryConstants';

const initialState = {
    categories: [],
    loading: null,
    success: null,
    error: null
};

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_REQUEST:
        case UPDATE_CATEGORY_REQUEST:
        case DELETE_CATEGORY_REQUEST:
        case CREATE_CATEGORY_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_CATEGORIES_SUCCESS:
            return { ...state, loading: false, success: true, error: null, categories: action.payload };
        case UPDATE_CATEGORY_SUCCESS:
        case DELETE_CATEGORY_SUCCESS:
        case CREATE_CATEGORY_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_CATEGORIES_FAILURE:
        case UPDATE_CATEGORY_FAILURE:
        case DELETE_CATEGORY_FAILURE:
        case CREATE_CATEGORY_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
