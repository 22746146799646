import {
    FETCH_REPORTS_REQUEST,
    FETCH_REPORTS_SUCCESS,
    FETCH_REPORTS_FAILURE,
    UPDATE_REPORT_REQUEST,
    UPDATE_REPORT_SUCCESS,
    UPDATE_REPORT_FAILURE
} from '../constants/ReportConstants';

const initialState = {
    reports: [],
    loading: null,
    success: null,
    error: null
};

export const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORTS_REQUEST:
        case UPDATE_REPORT_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_REPORTS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, reports: action.payload };
        case UPDATE_REPORT_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_REPORTS_FAILURE:
        case UPDATE_REPORT_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
