import {
    FETCH_BLOGS_REQUEST,
    FETCH_BLOGS_SUCCESS,
    FETCH_BLOGS_FAILURE,
    UPDATE_BLOG_REQUEST,
    UPDATE_BLOG_SUCCESS,
    UPDATE_BLOG_FAILURE,
    DELETE_BLOG_REQUEST,
    DELETE_BLOG_SUCCESS,
    DELETE_BLOG_FAILURE,
    CREATE_BLOG_REQUEST,
    CREATE_BLOG_SUCCESS,
    CREATE_BLOG_FAILURE
} from '../constants/BlogConstants';

const initialState = {
    blogs: [],
    loading: null,
    success: null,
    error: null
};

export const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BLOGS_REQUEST:
        case UPDATE_BLOG_REQUEST:
        case DELETE_BLOG_REQUEST:
        case CREATE_BLOG_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_BLOGS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, blogs: action.payload };
        case UPDATE_BLOG_SUCCESS:
        case DELETE_BLOG_SUCCESS:
        case CREATE_BLOG_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_BLOGS_FAILURE:
        case UPDATE_BLOG_FAILURE:
        case DELETE_BLOG_FAILURE:
        case CREATE_BLOG_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
