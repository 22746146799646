import {
    FETCH_REVIEWS_REQUEST,
    FETCH_REVIEWS_SUCCESS,
    FETCH_REVIEWS_FAILURE,
    BANNED_OR_UNBANNED_REVIEW_REQUEST,
    BANNED_OR_UNBANNED_REVIEW_SUCCESS,
    BANNED_OR_UNBANNED_REVIEW_FAILURE
} from '../constants/ReviewConstants';

const initialState = {
    reviews: [],
    loading: null,
    success: null,
    error: null
};

export const reviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REVIEWS_REQUEST:
        case BANNED_OR_UNBANNED_REVIEW_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_REVIEWS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, reviews: action.payload };
        case BANNED_OR_UNBANNED_REVIEW_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_REVIEWS_FAILURE:
        case BANNED_OR_UNBANNED_REVIEW_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };
        default:
            return state;
    }
};
