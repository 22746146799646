export const FETCH_VIDEOS_REQUEST = 'FETCH_VIDEOS_REQUEST';
export const FETCH_VIDEOS_SUCCESS = 'FETCH_VIDEOS_SUCCESS';
export const FETCH_VIDEOS_FAILURE = 'FETCH_VIDEOS_FAILURE';

export const UPDATE_VIDEO_REQUEST = 'UPDATE_VIDEO_REQUEST';
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS';
export const UPDATE_VIDEO_FAILURE = 'UPDATE_VIDEO_FAILURE';

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE';

export const CREATE_VIDEO_REQUEST = 'CREATE_VIDEO_REQUEST';
export const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS';
export const CREATE_VIDEO_FAILURE = 'CREATE_VIDEO_FAILURE';
