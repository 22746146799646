import {
    FETCH_ACCOUNTS_REQUEST,
    FETCH_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_FAILURE,
    UPDATE_ACCOUNT_REQUEST,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_FAILURE,
    DELETE_ACCOUNT_REQUEST,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAILURE,
    CREATE_ACCOUNT_REQUEST,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_FAILURE
} from '../constants/AccountConstants';

const initialState = {
    accounts: [],
    loading: null,
    success: null,
    error: null
};

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACCOUNTS_REQUEST:
        case UPDATE_ACCOUNT_REQUEST:
        case DELETE_ACCOUNT_REQUEST:
        case CREATE_ACCOUNT_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_ACCOUNTS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, accounts: action.payload };
        case UPDATE_ACCOUNT_SUCCESS:
        case DELETE_ACCOUNT_SUCCESS:
        case CREATE_ACCOUNT_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_ACCOUNTS_FAILURE:
        case UPDATE_ACCOUNT_FAILURE:
        case DELETE_ACCOUNT_FAILURE:
        case CREATE_ACCOUNT_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
