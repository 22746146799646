import {
    FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE
} from '../constants/ProductConstants';

const initialState = {
    products: [],
    loading: null,
    success: null,
    error: null
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS_REQUEST:
        case UPDATE_PRODUCT_REQUEST:
        case DELETE_PRODUCT_REQUEST:
        case CREATE_PRODUCT_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_PRODUCTS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, products: action.payload };
        case UPDATE_PRODUCT_SUCCESS:
        case DELETE_PRODUCT_SUCCESS:
        case CREATE_PRODUCT_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_PRODUCTS_FAILURE:
        case UPDATE_PRODUCT_FAILURE:
        case DELETE_PRODUCT_FAILURE:
        case CREATE_PRODUCT_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
