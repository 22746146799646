import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// reducer import
import customizationReducer from './reducers/customizationReducer';
import { userLoginReducer } from './reducers/UserReducers';
import { categoryReducer } from './reducers/CategoryReducers';
import { brandReducer } from './reducers/BrandReducers';
import { eventReducer } from './reducers/EventReducers';
import { accountReducer } from './reducers/AccountReducers';
import { postReducer } from './reducers/PostReducer';
import { reviewReducer } from './reducers/ReviewReducers';
import { blogReducer } from './reducers/BlogReducers';
import { couponReducer } from './reducers/CouponReducers';
import { videoReducer } from './reducers/VideoReducers';
import { productReducer } from './reducers/ProductReducers';
import { reportReducer } from './reducers/ReportReducers';

// ==============================|| COMBINE REDUCER ||============================== //
const reducer = combineReducers({
    customization: customizationReducer,
    userLogin: userLoginReducer,
    accountReducerState: accountReducer,
    categoryReducerState: categoryReducer,
    brandReducerState: brandReducer,
    eventReducerState: eventReducer,
    postReducerState: postReducer,
    reviewReducerState: reviewReducer,
    blogReducerState: blogReducer,
    couponReducerState: couponReducer,
    videoReducerState: videoReducer,
    productReducerState: productReducer,
    reportReducerState: reportReducer
});

const userInfoFromLocalStogare = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
    userLogin: {
        status: 'From local storage',
        userInfo: userInfoFromLocalStogare
    }
};

// ==============================|| REDUX - MAIN STORE ||============================== //
const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
