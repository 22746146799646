import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import ProtectedRoute from './ProtectedRoute';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Building = Loadable(lazy(() => import('views/building')));
const Officer = Loadable(lazy(() => import('views/officer')));
const ManageAccount = Loadable(lazy(() => import('views/manage-account')));
const ManageProduct = Loadable(lazy(() => import('views/manage-product')));
const ManageCategory = Loadable(lazy(() => import('views/manage-category')));
const ManageBrand = Loadable(lazy(() => import('views/manage-brand')));
const ManageEvent = Loadable(lazy(() => import('views/manage-event')));
const ManagePost = Loadable(lazy(() => import('views/manage-post')));
const ManageReview = Loadable(lazy(() => import('views/manage-review')));
const ManageBlog = Loadable(lazy(() => import('views/manage-blog')));
const ManageCoupon = Loadable(lazy(() => import('views/manage-coupon')));
const ManageVideo = Loadable(lazy(() => import('views/manage-video')));
const ManageReport = Loadable(lazy(() => import('views/manage-report')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isAllowed) => ({
    path: '/',
    element: <ProtectedRoute isAllowed={isAllowed} children={<MainLayout />} />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'building',
            element: <Building />
        },
        {
            path: 'officer',
            element: <Officer />
        },
        {
            path: 'manage-account',
            element: <ManageAccount />
        },
        {
            path: 'manage-product',
            element: <ManageProduct />
        },
        {
            path: 'manage-category',
            element: <ManageCategory />
        },
        {
            path: 'manage-brand',
            element: <ManageBrand />
        },
        {
            path: 'manage-event',
            element: <ManageEvent />
        },
        {
            path: 'manage-post',
            element: <ManagePost />
        },
        {
            path: 'manage-review',
            element: <ManageReview />
        },
        {
            path: 'manage-blog',
            element: <ManageBlog />
        },
        {
            path: 'manage-coupon',
            element: <ManageCoupon />
        },
        {
            path: 'manage-video',
            element: <ManageVideo />
        },
        {
            path: 'manage-report',
            element: <ManageReport />
        }
    ]
});

export default MainRoutes;
