import {
    FETCH_EVENTS_REQUEST,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAILURE,
    UPDATE_EVENT_REQUEST,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAILURE,
    DELETE_EVENT_REQUEST,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAILURE,
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_FAILURE
} from '../constants/EventConstants';

const initialState = {
    events: [],
    loading: null,
    success: null,
    error: null
};

export const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENTS_REQUEST:
        case UPDATE_EVENT_REQUEST:
        case DELETE_EVENT_REQUEST:
        case CREATE_EVENT_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_EVENTS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, events: action.payload };
        case UPDATE_EVENT_SUCCESS:
        case DELETE_EVENT_SUCCESS:
        case CREATE_EVENT_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_EVENTS_FAILURE:
        case UPDATE_EVENT_FAILURE:
        case DELETE_EVENT_FAILURE:
        case CREATE_EVENT_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
