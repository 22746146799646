import {
    FETCH_VIDEOS_REQUEST,
    FETCH_VIDEOS_SUCCESS,
    FETCH_VIDEOS_FAILURE,
    UPDATE_VIDEO_REQUEST,
    UPDATE_VIDEO_SUCCESS,
    UPDATE_VIDEO_FAILURE,
    DELETE_VIDEO_REQUEST,
    DELETE_VIDEO_SUCCESS,
    DELETE_VIDEO_FAILURE,
    CREATE_VIDEO_REQUEST,
    CREATE_VIDEO_SUCCESS,
    CREATE_VIDEO_FAILURE
} from '../constants/VideoConstants';

const initialState = {
    videos: [],
    loading: null,
    success: null,
    error: null
};

export const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VIDEOS_REQUEST:
        case UPDATE_VIDEO_REQUEST:
        case DELETE_VIDEO_REQUEST:
        case CREATE_VIDEO_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_VIDEOS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, videos: action.payload };
        case UPDATE_VIDEO_SUCCESS:
        case DELETE_VIDEO_SUCCESS:
        case CREATE_VIDEO_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_VIDEOS_FAILURE:
        case UPDATE_VIDEO_FAILURE:
        case DELETE_VIDEO_FAILURE:
        case CREATE_VIDEO_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
