import {
    FETCH_COUPONS_REQUEST,
    FETCH_COUPONS_SUCCESS,
    FETCH_COUPONS_FAILURE,
    UPDATE_COUPON_REQUEST,
    UPDATE_COUPON_SUCCESS,
    UPDATE_COUPON_FAILURE,
    DELETE_COUPON_REQUEST,
    DELETE_COUPON_SUCCESS,
    DELETE_COUPON_FAILURE,
    CREATE_COUPON_REQUEST,
    CREATE_COUPON_SUCCESS,
    CREATE_COUPON_FAILURE
} from '../constants/CouponConstants';

const initialState = {
    coupons: [],
    loading: null,
    success: null,
    error: null
};

export const couponReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COUPONS_REQUEST:
        case UPDATE_COUPON_REQUEST:
        case DELETE_COUPON_REQUEST:
        case CREATE_COUPON_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_COUPONS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, coupons: action.payload };
        case UPDATE_COUPON_SUCCESS:
        case DELETE_COUPON_SUCCESS:
        case CREATE_COUPON_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_COUPONS_FAILURE:
        case UPDATE_COUPON_FAILURE:
        case DELETE_COUPON_FAILURE:
        case CREATE_COUPON_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
