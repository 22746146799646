// assets
import {
    IconUsers,
    IconBrandProducthunt,
    IconCategory,
    IconBrandAdobe,
    IconArticle,
    IconEdit,
    IconFlag,
    IconCalendarEvent,
    IconTicket,
    IconNews,
    IconBrandYoutube
} from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconBrandProducthunt,
    IconCategory,
    IconBrandAdobe,
    IconArticle,
    IconEdit,
    IconFlag,
    IconCalendarEvent,
    IconTicket,
    IconNews,
    IconBrandYoutube
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        // {
        //     id: 'sample-page',
        //     title: 'Sample Page',
        //     type: 'item',
        //     url: '/sample-page',
        //     icon: icons.IconBrandChrome,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'documentation',
        //     title: 'Documentation',
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/',
        //     icon: icons.IconHelp,
        //     external: true,
        //     target: true
        // },
        // {
        //     id: 'building',
        //     title: 'Building',
        //     type: 'item',
        //     url: '/building',
        //     icon: icons.IconBrandChrome,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'officer',
        //     title: 'Officer',
        //     type: 'item',
        //     url: '/officer',
        //     icon: icons.IconBrandChrome,
        //     breadcrumbs: false
        // },
        {
            id: 'manage-account',
            title: 'Quản lý Người dùng',
            type: 'item',
            url: '/manage-account',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'manage-product',
            title: 'Quản lý Sản phẩm',
            type: 'item',
            url: '/manage-product',
            icon: icons.IconBrandProducthunt,
            breadcrumbs: false
        },
        {
            id: 'manage-category',
            title: 'Quản lý Danh mục',
            type: 'item',
            url: '/manage-category',
            icon: icons.IconCategory,
            breadcrumbs: false
        },
        {
            id: 'manage-brand',
            title: 'Quản lý Nhãn hàng',
            type: 'item',
            url: '/manage-brand',
            icon: icons.IconBrandAdobe,
            breadcrumbs: false
        },
        {
            id: 'manage-post',
            title: 'Quản lý Bài viết',
            type: 'item',
            url: '/manage-post',
            icon: icons.IconArticle,
            breadcrumbs: false
        },
        {
            id: 'manage-review',
            title: 'Quản lý Đánh giá',
            type: 'item',
            url: '/manage-review',
            icon: icons.IconEdit,
            breadcrumbs: false
        },
        {
            id: 'manage-report',
            title: 'Quản lý Báo cáo',
            type: 'item',
            url: '/manage-report',
            icon: icons.IconFlag,
            breadcrumbs: false
        },
        {
            id: 'manage-event',
            title: 'Quản lý Sự kiện',
            type: 'item',
            url: '/manage-event',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false
        },
        {
            id: 'manage-coupon',
            title: 'Quản lý Mã giảm giá',
            type: 'item',
            url: '/manage-coupon',
            icon: icons.IconTicket,
            breadcrumbs: false
        },
        {
            id: 'manage-blog',
            title: 'Quản lý Blog',
            type: 'item',
            url: '/manage-blog',
            icon: icons.IconNews,
            breadcrumbs: false
        },
        {
            id: 'manage-video',
            title: 'Quản lý Video',
            type: 'item',
            url: '/manage-video',
            icon: icons.IconBrandYoutube,
            breadcrumbs: false
        }
    ]
};

export default other;
