import {
    FETCH_POSTS_REQUEST,
    FETCH_POSTS_SUCCESS,
    FETCH_POSTS_FAILURE,
    BANNED_OR_UNBANNED_POST_REQUEST,
    BANNED_OR_UNBANNED_POST_SUCCESS,
    BANNED_OR_UNBANNED_POST_FAILURE
} from '../constants/PostConstants';

const initialState = {
    posts: [],
    loading: null,
    success: null,
    error: null
};

export const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSTS_REQUEST:
        case BANNED_OR_UNBANNED_POST_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_POSTS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, posts: action.payload };
        case BANNED_OR_UNBANNED_POST_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_POSTS_FAILURE:
        case BANNED_OR_UNBANNED_POST_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };
        default:
            return state;
    }
};
