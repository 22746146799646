import {
    FETCH_BRANDS_REQUEST,
    FETCH_BRANDS_SUCCESS,
    FETCH_BRANDS_FAILURE,
    UPDATE_BRAND_REQUEST,
    UPDATE_BRAND_SUCCESS,
    UPDATE_BRAND_FAILURE,
    DELETE_BRAND_REQUEST,
    DELETE_BRAND_SUCCESS,
    DELETE_BRAND_FAILURE,
    CREATE_BRAND_REQUEST,
    CREATE_BRAND_SUCCESS,
    CREATE_BRAND_FAILURE
} from '../constants/BrandConstants';

const initialState = {
    brands: [],
    loading: null,
    success: null,
    error: null
};

export const brandReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BRANDS_REQUEST:
        case UPDATE_BRAND_REQUEST:
        case DELETE_BRAND_REQUEST:
        case CREATE_BRAND_REQUEST:
            return { ...state, loading: true, success: null, error: null };

        case FETCH_BRANDS_SUCCESS:
            return { ...state, loading: false, success: true, error: null, brands: action.payload };
        case UPDATE_BRAND_SUCCESS:
        case DELETE_BRAND_SUCCESS:
        case CREATE_BRAND_SUCCESS:
            return { ...state, loading: false, success: true, error: null };

        case FETCH_BRANDS_FAILURE:
        case UPDATE_BRAND_FAILURE:
        case DELETE_BRAND_FAILURE:
        case CREATE_BRAND_FAILURE:
            return { ...state, loading: null, success: null, error: action.payload };

        default:
            return state;
    }
};
